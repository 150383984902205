<template>
  <div class="auth pa-3">
    <form @submit.prevent="Login">
      <v-card-text>
        <v-row align="center" class="mx-0">
          <v-col cols="12">
            <span class="mb-3">Your email address *</span>
            <v-text-field
              solo
              hide-details="auto"
              v-model="form.email"
              :rules="[() => !!form.email || 'This field is required']"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <span class="mb-3">Password *</span>
            <v-text-field
              solo
              hide-details="auto"
              :rules="[() => !!form.password || 'This field is required']"
              v-model="form.password"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-btn color="primary" type="submit" outlined class="px-10">
              Login
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-banner
        v-model="notifBanner"
        transition="slide-y-transition"
        color="error" dark
        v-if="errorMessages"
      >
        {{errorMessages}}
        <template v-slot:actions="{ dismiss }">
          <v-btn text @click="dismiss"> Dismiss </v-btn>
        </template>
      </v-banner>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      errorMessages: "",
      submitForm: false,

      notifBanner: true,
    };
  },

  methods: {
    Login() {
      this.submitForm = true;
      return new Promise(() => {
        axios
          .post(
            "customer/login",
            {
              email: this.form.email,
              password: this.form.password,
            },
            {
              headers: {
                "Access-Control-Allow-Headers": "*",
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            localStorage.setItem("access_token", response.data.data.APItoken);
            this.$router.push({ name: "dashboard" });
            this.submitForm = false;
          })

          .catch((error) => {
            console.log(error)
            this.notifBanner = true
            this.errorMessages = error.response.data.message
            this.submitForm = false;
          });
      });
    },
  },
};
</script>
